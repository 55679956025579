.candidate-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  word-break: break-word;

  p,
  li,
  span {
    font-size: 15px;
  }

  h6 {
    font-size: 17px;
  }

  .candidate-modal-hide-button {
    font-size: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(237, 242, 248);
  }

  .candidate-modal-wrapper {
    overflow: hidden;
    position: relative;
    padding: 50px;
    border-radius: 20px;
    background-color: white;
    max-width: 1200px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 70px;
    width: 100%;
    max-height: 100%;
    overflow: auto;

    h3 {
      text-align: center;
      width: 100%;
      margin: 0;
    }

    .candidate-modal-header {
      display: flex;
      align-items: stretch;
      gap: 40px;
      border-radius: 30px;
      padding: 30px;
      background-color: rgb(241, 243, 252);
      flex-wrap: wrap;

      .candidate-modal-header-left {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 10px 0;
        min-width: 250px;

        .candidate-modal-header-PP {
          height: 130px;
          width: 130px;
          border: 2px solid white;
          border-radius: 50%;
          background-color: rgb(203, 213, 228);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
          font-size: 50px;
        }

        .candidate-modal-header-left-top {
          display: flex;
          flex-direction: column;
          gap: 10px;

          h2 {
            margin: 0;
          }

          span {
            font-size: 16px;
          }
        }
      }

      .candidate-modal-header-right {
        position: relative;

        &-wrapper {
          min-width: 300px;
          flex: 1;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          flex-wrap: wrap;

          padding: 30px;
          background-color: white;
          border-radius: 20px;
          box-shadow: 0 3px 7px rgba(0, 0, 0, 0.2);
          overflow: hidden;

          h6 {
            margin-bottom: 10px;
          }

          ul {
            margin: 0;
            padding-left: 0px;
            display: flex;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            overflow: auto;
            max-width: 100%;

            li {
              opacity: 0.8;
              border-radius: 7px;
              padding: 0 7px;
              list-style: none;
              white-space: nowrap;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;

              &.blue {
                border: 1px solid rgba(0, 78, 196, 0.7);
              }

              &.red {
                border: 1px solid rgba(179, 0, 0, 0.7);
              }

              &.green {
                border: 1px solid rgba(0, 153, 51, 0.7);
              }

              &.yellow {
                border: 1px solid rgba(255, 145, 0, 0.7);
              }
            }
          }
        }

        &-edit {
          position: absolute;
          right: -15px;
          top: -15px;
          z-index: 1;
          font-size: 30px;
          cursor: pointer;
        }
      }
    }

    .candidate-modal-timeline-container {
      display: flex;
      gap: 85px;
      row-gap: 40px;
      flex-wrap: wrap;
      flex-direction: column;

      .candidate-modal-timeline-labels {
        display: flex;
      }

      .candidate-modal-timeline-label {
        display: flex;
        align-items: center;
        margin: 0;
        flex: 1;

        h5 {
          margin: 0;
          color: rgb(56, 56, 56);
        }

        &.reverse {
          flex-direction: row-reverse;

          span {
            background-color: rgba(0, 68, 255, 0.1);
            color: rgb(0, 0, 117);
          }
        }

        span {
          padding: 10px;
          background-color: rgba(255, 0, 0, 0.1);
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 7px;
          color: rgb(161, 0, 0);
          font-size: 18px;
        }
      }
    }

    .candidate-modal-timeline-spinner {
      display: inline-block;
    }

    .candidate-modal-timeline-content {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      width: 100%;

      .candidate-modal-timeline-line {
        width: 4px;
        position: absolute;
        background-color: rgb(237, 242, 250);
      }

      .candidate-modal-timeline {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        width: 90%;

        .candidate-modal-timeline-year {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          width: 100%;
          flex-wrap: wrap;

          .candidate-modal-timeline-date {
            background-color: white;
            position: relative;
            width: 50px;
            text-align: center;
            color: gray;
            border-radius: 7px;
            background-color: rgb(234, 241, 255);
            font-size: 14px;
          }

          .candidate-modal-timeline-cards-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            .candidate-modal-timeline-card-wrapper,
            .candidate-modal-timeline-card-editor {
              position: relative;

              padding: 20px 20px;
              background-color: white;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
              border-radius: 20px;
              border: 1px solid rgba(0, 102, 255, 0.2);
              width: 100%;

              &.left {
                border: 1px solid rgba(255, 0, 0, 0.2);
              }

              p {
                margin: 0;
              }

            }


            .candidate-modal-timeline-card.drag,
            .candidate-modal-timeline-card-editor.drag {
              opacity: 0.4;
            }

            .candidate-modal-timeline-card {
              position: relative;

              &-wrapper {
                position: relative;
              }

              &-grab {
                position: absolute;
                left: -15px;
                top: -20px;
                z-index: 1;
                font-size: 30px;
                cursor: grab;
              }

              &-recommended {
                position: absolute;
                right: -15px;
                top: -20px;
                z-index: 1;
                font-size: 30px;
                cursor: pointer;
                color: blue;
              }

              &-subtitle, &-organization {
                font-size: 14px;
                font-style: italic;
              }

              &-separator {
                color: green;
                text-align: center;
                padding-top: 1rem;

                &-line {
                  width: 40%;
                  border-bottom: 1px dashed black;
                }

                &-icon {
                  font-size: 30px;
                  cursor: pointer;
                }

                &-line,
                &-icon {
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }

            .candidate-modal-timeline-card-editor {

              &-title,
              &-year {
                margin-bottom: 10px;

                &-container {
                  display: flex;
                }
              }

              &-relevant {
                margin: 10px;
              }

              &-current {
                margin: 0 10px;
              }

              &-to-label {
                margin: 0 10px;
              }

              &-button-bar {
                display: flex;
                justify-content: space-evenly;
                margin-top: 0.5rem;
                font-size: 20px;
              }

              &-button-bar>* {
                margin-left: 0.5rem;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .candidate-modal-timeline {
      &-edit-button-bar {
        display: flex;
        justify-content: center;
        position: relative;

        &-history-navigator {
          position: absolute;
          right: 20px;
          top: 10px;
          font-size: 20px;

          &-icon {
            padding: 0 0.2rem;
            cursor: not-allowed;
            opacity: 0.2;
            color: blue;

            &.enabled {
              opacity: 1;
              cursor: pointer;
            }
          }

        }
      }
    }
  }
}

.candidate-modal .candidate-modal-wrapper .candidate-modal-timeline-content.edit-mode .candidate-modal-timeline .candidate-modal-timeline-year .candidate-modal-timeline-cards-wrapper .candidate-modal-timeline-card:not(.empty):hover {
  border: grey dashed 3px;
  cursor: text;
}

@media screen and (max-width: 800px) {
  .candidate-modal {
    padding: 0 !important;

    .candidate-modal-wrapper {
      padding: 20px !important;
      border-radius: 0 !important;
      height: 100%;
      width: 100%;

      .candidate-modal-header-PP {
        width: 90px !important;
        height: 90px !important;
        font-size: 35px !important;
      }

      .candidate-modal-timeline-container {
        column-gap: 0px !important;
      }

      .candidate-modal-timeline-line {
        display: none;
      }

      .candidate-modal-timeline {
        gap: 50px !important;

        .candidate-modal-timeline-year {
          flex-direction: column;

          .candidate-modal-timeline-date {
            order: -1;
            padding: 0 !important;
          }
        }
      }
    }
  }
}