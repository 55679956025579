.error {
    color: red;
    text-align: center;
}

.organization-list {

    &-results {
        &-title {
            display: flex;
            justify-content: space-between;
        }

        &-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 1rem;
        }

        &-noitems {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    &-item {
        width: 20vw;
        margin: 1rem;
        padding: 0.5rem;

        .card {
            &-title {
                display: flex;
                flex-direction: column;
            }

            &-body {
                margin-top: 15px;
                padding: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 60%;
            }

            &-footer {
                display: flex;

                .btn {
                    flex: 1
                }
            }
        }

        &-header {
            &-icon {
                position: absolute;
                top: 0px;
                right: 3px;

                &.active {
                    color: green;
                }
            }
        }

        &-image {
            height: 130px;
            background-color: whitesmoke;
            width: 100%;
            position: relative;

            &-logo {

                img {
                    object-fit: contain !important;
                }

                position: absolute;
                bottom: -25px;
                left: 25px;
                height: 80px;
                width: 80px;
                background-color: rgb(255, 255, 255);
                border-radius: 5px;
                box-shadow: 0 5px 40px rgba(0, 0, 0, 0.15);
                overflow: hidden;
            }

            img {
                height: 100% !important;
                width: 100% !important;
                border-radius: 0 !important;
                object-fit: cover !important;
                position: absolute;
                transition: transform 300ms;
            }
        }

        &-title {
            font-weight: bold;
        }

        &-summary {
            padding-top: 1rem;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
        }

    }
}

.spinner-container {
    width: 100%;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}