.error {
    color: red;
    text-align: center;
}

.job-list {

    &-results {
        &-title {
            display: flex;
            justify-content: space-between;
        }

        &-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-top: 1rem;
        }

        &-noitems {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
        }
    }
}

.spinner-container {
    width: 100%;
    text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}