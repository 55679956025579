$secondary : #f4d47d;
$primary : #183270;
$disabled : #adb5bd;
$theme-colors: (
    "primary": $primary,
    "secondary":$secondary,
    "success": #3c763d,
    "disabled": $disabled,
    "danger" : #ff4136,
    "warning" : #ff7b00
);

@import "~bootstrap/scss/bootstrap-utilities";

//Generate utilites for Cursor
$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed
    ),
  )
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import 'react-bootstrap-typeahead/css/Typeahead.css';
@import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
@import 'react-quill/dist/quill.snow.css';
@import "react-datepicker/dist/react-datepicker.css";

$all-colors: map-merge-multiple($blues, $indigos, $purples, $pinks, $reds, $oranges, $yellows, $greens, $teals, $cyans);

$utilities: map-merge(
  $utilities,
  (
    "color": map-merge(
      map-get($utilities, "color"),
      (
        values: map-merge(
          map-get(map-get($utilities, "color"), "values"),
          (
            $all-colors
          ),
        ),
      ),
    ),
  )
);

body {
    min-height: 100vh;
    align-items: center;

    background-color: #f5f5f5;

}

.badge {
    &.competency {
        background-color: #c0c0c0;
        color: #000000
    }

    &.region {
        background-color: #c0c0c0;
        color: #000000
    }

}


.login-box {
    background: url(https://i.imgur.com/73BxBuI.png);
    background-size: cover;
    background-position: center;
    padding: 50px;
    margin: 50px auto;
    min-height: 700px;
    -webkit-box-shadow: 0 2px 60px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 60px -5px rgba(0, 0, 0, 0.1);
}

.logo {
    font-family: "Script MT";
    font-size: 54px;
    text-align: center;
    color: $primary;
    margin-bottom: 50px;
}

.logo .logo-font {
    color: $primary;
}

@media only screen and (max-width: 767px) {
    .logo {
        font-size: 34px;
    }
}

.header-title {
    text-align: center;
    margin-bottom: 50px;
}

.login-form {
    max-width: 300px;
    margin: 0 auto;
}

.japd {
    &-enabled {
        color: #000000;
        background: linear-gradient(to right, blue 33%, white 33%, white 66%, red 66%)
    }

    &-disabled {
        background-color: $disabled;
    }
}

.login-form .form-control {
    border-radius: 0;
    margin-bottom: 30px;
}

.login-form .form-group {
    position: relative;
}

.login-form .form-group .forgot-password {
    position: absolute;
    top: 6px;
    right: 15px;
}

.login-form .btn {
    border-radius: 0;
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.login-form .btn.btn-primary {
    background: $primary;
    border-color: $primary;
    $color: $secondary;
}

.slider-feature-card {
    background: #fff;
    max-width: 280px;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
    -webkit-box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 25px -3px rgba(0, 0, 0, 0.1);
}

.slider-feature-card img {
    height: 80px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.slider-feature-card h3,
.slider-feature-card p {
    margin-bottom: 30px;
}

.carousel-indicators {
    bottom: -50px;
}

.carousel-indicators li {
    cursor: pointer;
}


.card-stats .icon-big {
    font-size: 3em;
    min-height: 64px;
    color: $primary;
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 200px;
    z-index: 100;
    padding: 90px 0 0;
    z-index: 99;
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 11.5rem;
        padding: 0;
    }
}

.navbar {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .1);
}

@media (min-width: 767.98px) {
    .navbar {
        top: 0;
        position: sticky;
        z-index: 999;
    }
}

.sidebar .nav-link {
    color: #333;

    svg {
        width: 20px;
    }
}

.sidebar .nav-link.active {
    color: #0d6efd;
}

.candidate-item {


    &-linkedin {
        color: #0a66c2;
        cursor: pointer
    }

    &.selected {
        border: 8px solid $secondary;

        .candidate-item-header {
            border-radius: 0px;
            background-color: $secondary;

        }
    }

    &-header {
        display: flex;
        align-items: flex-start;

        .candidate-item-picture {
            width: 64px;
            height: 100%;
        }
    }
}

.fa-file-pdf {
    color: #F40F02;

    &.delete:after {

        position: absolute;
        content: "/";
        color: red;
        font-weight: 700;
        font-size: 1.7em;
        left: 7px;
        top: -10px;


    }

}

.cursor-pointer {
    cursor: pointer;
}

@media all and (max-width: 960px) {
    .offcanvas {
        width: 75%;
    }
}

@media all and (max-width: 1800px) and (min-width: 961px) {
    .offcanvas {
        width: 50%;
    }
}

@media all and (min-width: 1800px) {
    .offcanvas {
        width: 25%;
    }
}



.candidate-interests-form li,
.candidate-interests-form ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.candidate-update-modal>.modal-dialog {
    min-height: 550px;
}

.candidate-update-modal label.form-label {
    font-weight: bold;
}

.predefined-filter a:hover {
    cursor: pointer;
    font-weight: bold;
}

.optionGroup {
    font-weight: bold;

}

.optionChild {
    padding-left: 15px;
}


.icon-check {
    position: absolute;
    font-size: 32px;
    width: 20px;
    overflow: visible;
    /* border-radius: 25px; */
    z-index: 155;

    &-left {
        /* height: 20px; */
        left: -8px;
        top: -10px;
    }

    &-right {
        /* height: 20px; */
        right: -10px;
        top: -15px;
    }



}