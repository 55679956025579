.editable-job-list-item {
    width: 100%;
    margin: 0.5rem;

    &-container {
        .header {
            .backward {
                cursor: pointer;
            }
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        width: 100%;
        height: 100%;
        gap: 1rem;
    }

    .left {
        display: flex;
        flex-direction: column;
        width: 60%;
        height: 100%;
        gap: 0.5rem;

        .skills {
            .rbt-input-multi {
                height: 58px;
                padding-top: 1rem;
                overflow: auto;

                .rbt-input-wrapper {
                    margin: 0;
                }

                &:focus {
                    border-color: inherit;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                }
            }

            .svg-inline--fa {
                padding-right: .5rem;
            }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        width: 40%;

        .form-floating {
            flex-grow: 1;

            .form-select {
                height: 100%;
            }
        }
    }

    .description {
        height: 300px;
        flex: 1;

        .quill {
            .ql-container {
                height: 250px;
            }
        }
    }

    .bottom {
        position: sticky;
        bottom: 0;
        background-color: white;
        padding: 1rem 0;
    }
}

@media screen and (max-width:1400px) {
    .editable-job-list-item {
        .description {
            flex-direction: column;
        }
    }
}

@media screen and (max-width:1200px) {
    .editable-job-list-item {

        .left,
        .right {
            width: 50%;
        }
    }
}

@media screen and (max-width:1000px) {
    .editable-job-list-item {

        &-header {
            flex-direction: column;
        }

        .left,
        .right {
            width: 100%;
        }
    }
}

@media screen and (max-width:529px) {
    .editable-job-list-item {
        .location {
            width: 100%;
        }
    }
}