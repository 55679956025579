.job-list-item {
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
        box-shadow: 0 3px 8px rgb(0 0 0 / 30%);
    }

    .left {
        flex: 1;
        position: relative;
        padding: 1rem;
        display: flex;
        flex-direction: column;

        &-company-name {
            opacity: .6;
            font-size: 14px;
            white-space: nowrap;
        }

        &-title {
            color: black;
            font-size: 17px;
            font-weight: bold;
            line-height: 1.5;
            margin-top: .5rem;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        &-summary {
            margin-top: .5rem;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &-infos {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            padding-top: 1rem;

            div {
                span {
                    width: 200px;
                    font-weight: bold;

                    &:after {
                        content: ' : ';
                    }
                }
            }
        }

        &-flag {
            --size: 40px;
            position: absolute;
            top: 0rem;
            right: 1rem;
            width: var(--size);
            height: 40px;
            box-sizing: content-box;
            padding-top: 15px;
            font-size: 11px;
            letter-spacing: 0.2em;
            text-align: center;
            text-transform: uppercase;
            color: white;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-bottom: 13px solid white;
                border-left: calc(var(--size) / 2) solid transparent;
                border-right: calc(var(--size) / 2) solid transparent;
            }

            &.green {
                background-color: green;
            }

            &.gray {
                background-color: gray;
            }

            &.red {
                background-color: red;
            }
        }

        &-bottom {
            margin-top: auto;
            display: flex;
            gap: .5rem;
            list-style-type: none;
            padding-left: unset;
            margin-bottom: unset;

            li {
                font-size: 12px;
                font-style: italic;
            }
        }
    }

    .right {
        width: 100px;
        height: auto;

        &-image {
            position: relative;
            width: 100%;
            height: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            &-background {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                border-radius: 0;
                object-fit: cover;
                transition: transform .3s;
            }

            &-logo {
                position: relative;
                border-radius: 5px;
                overflow: hidden;
                aspect-ratio: 1;
                height: 80px;
                background-color: #fff;
                box-shadow: 0 5px 40px rgb(0 0 0 / 15%);

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                    object-fit: cover;
                    transition: transform .3s;
                }
            }
        }
    }
}

@media screen and (max-width:600px) {
    .job-list-item {
        .right {
            display: none;
        }
    }
}