.modal-dialog {
    overflow-y: initial !important
}

.height-80 {
    height: 80vh;
    overflow-y: auto;
}

.skills-filter {
    margin-top: 0.5rem;

    .rbt-input-multi {
        height: calc(3.5rem + 2px);
        line-height: 1.25;
    }
}